import React, { useState, useEffect } from "react";
import "./App.css";

function App() {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer); // Cleanup timer
  }, []);

  function calculateTimeLeft() {
    const launchDate = new Date("2024-11-01T00:00:00");
    const now = new Date();
    const difference = launchDate - now;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  }

  // Helper function to pad values with leading zeros
  const padWithZeros = (value) => {
    return String(value).padStart(2, "0");
  };

  return (
    <div className="App">
      <div className="content">
        <h1>Coming Soon..</h1>
        <p>
          Get ready!! Something extraordinary is coming your way. Stay tuned for
          the big reveal!
        </p>
        <p>
          <em>Thank you... Team CutCals</em>
        </p>
        <div className="countdown">
          {timeLeft.days !== undefined ? (
            <>
              <span>[{padWithZeros(timeLeft.days)}]:</span>
              <span>[{padWithZeros(timeLeft.hours)}]:</span>
              <span>[{padWithZeros(timeLeft.minutes)}]:</span>
              <span>[{padWithZeros(timeLeft.seconds)}]</span>
            </>
          ) : (
            <span>Time's up!</span>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
